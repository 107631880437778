import React from "react";

import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/pkseo";
import Navigation from "../../components/common/navigation/navigation";

import { Container } from "../../components/global";

import Header from "../../components/sections/terms-privacy/header";
import Footer from "../../components/sections/footer";

import { Content, Section, P, H1, H2, UL } from "../../styles/TCandPrivacyStyles";

const TermsAndConditions = () => (
  <Layout>
    <SEO title="Terms of Service" />
    <Navigation background="white" />
    <Header {...pageData.headerData} />
    <Container>
      <Content>
        <Section>
          <H1>Welcome to OPay!</H1>
          <P>
            The OPay service, website and/or software application (collectively, the “Service”) enables you to transfer
            funds between your mobile money wallet and your bank account, as well as to the wallet or account of another
            user. You may also use your supported mobile money wallet, account or credit card to pay for products and
            services offered by third parties (“Products”). The Service is operated by OPay Digital Services Limited
            (“OPay”), subject to these Terms of Service (“Terms”).
          </P>
        </Section>

        <Section>
          <H2>
            <span>Terms of service</span>
          </H2>
          <P>
            <span>This is a contract.</span> These Terms constitute a contract between you and OPay. You may not use the
            Service if you do not accept these Terms. By using any part of the Service you accept these Terms. If you
            are under eighteen (18) years of age, you may not use the Service.
          </P>
          <P>
            <span>You must register an account with valid information.</span>
            To use the Service, you must: (a) provide a valid mobile phone number; (b) agree to the creation of an
            account associated with such number; (c) accept the present Terms; and (d) submit such other and additional
            information as OPay may request. You agree to provide true, accurate and complete information about yourself
            as prompted by OPay during the account registration process. You will be given the option of receiving a
            unique PIN for purposes of reusing your account. You are responsible for keeping your PIN secure.
          </P>
          <P>
            <span>Third party merchants offer the goods & services. </span>
            The Service provides you with a method of discovering offers from independent third party merchants
            (“Merchants”). These Merchants sell their respective Products subject to their own terms and conditions. For
            example:
            <UL>
              <li>
                <span>Airtime: </span>If you choose to purchase pre-paid airtime, you do so pursuant to the relevant
                terms and conditions of your mobile telecommunications provider.
              </li>
              <li>
                <span>Utilities: </span>If you choose to purchase pre-paid electricity or water, you do so pursuant to
                the relevant terms and conditions of the utility provider.
              </li>
            </UL>
          </P>

          <P>
            If you decide to purchase Products such as these from third party Merchants, you do so at your own risk and
            subject to the relevant Merchant’s terms and conditions. The Products are not investigated, monitored, or
            checked for accuracy, reliability, appropriateness or completeness by OPay. By permitting you to discover
            Products via the Service, OPay does not imply approval or endorsement of such Products. You agree that OPay
            is not responsible or liable for the Products you purchase from Merchants.
          </P>
          <P>
            <span>Payments are procesed by third parties.</span> In order to facilitate your purchases from Merchants,
            OPay has integrated its Service with the billing solutions of certain mobile money providers and credit card
            issuing banks (“Payment Processors”). If you purchase a Product from a Merchant using your supported mobile
            money wallet or account or credit card, payments will be processed by such Payment Processors. Payment
            Processors have their own terms and conditions governing your use of their payment services. You understand
            and agree that OPay does not process payments and is not responsible or liable for any transactions in which
            you engage. By engaging in a purchase transaction using your mobile money account or credit card, you
            authorize the Merchant (or OPay on its behalf) to bill your account or card for the purchase amount. Your
            privacy is important to us. OPay takes the matters of protection and security of its users’ information very
            seriously. OPay’s privacy policy governing the Service is attached to these Terms (“Privacy Policy”). The
            Privacy Policy is incorporated into these Terms by this reference. By using the Service, you agree to the
            use of your data in accordance with OPay’s Privacy Policy. The Privacy Policy addresses only the information
            collected by OPay in providing the Service to you. Merchants and Payment Processors have their own
            information gathering practices that will apply when you choose to purchase Products.
          </P>
          <P>
            <span>Product inquiries should be submitted to the relevant Merchant.</span>
            If you have any questions, concerns or requests related to a Product you have purchased from a Merchant, you
            should contact the Merchant directly or contact us at ng-support@opay-inc.com. Each Merchant maintains its
            own customer contact desk for handling such requests, however OPay may do its best effort to assist you. If
            you wish to submit a complaint about the practices of a Merchant offering its Products via the Service, you
            may contact us by email at ng-support@opay-inc.com.
          </P>
          <P>
            <span>You must not use the Service to violate any laws. </span>
            You must not use the Service to violate or infringe the rights of any other person, including the rights of
            other users, OPay’s rights in the Service or Merchants’ rights in their Products. You must not breach any
            laws or regulations when using the Service or attempt to disrupt or interfere with the security or
            functionality of the Service. In the event OPay has the suspicion you are using the Services for illegal
            activities such as fraud, OPay will block your account immediately.
          </P>
          <P>
            <span>OPay may contact you regarding your account or the Service. </span>
            You expressly agree that, as part of the Service, you may, from time to time, receive communications from
            OPay via email, instant message, telephone, text message (SMS) or other means. You may stop receiving
            promotional messages by emailing your request to opt-out, along with your cell phone number, to
            ng-support@opay-inc.com, or following the opt-out instructions in the message. Even if you choose to opt out
            of receiving promotional messages, you may not opt out of receiving service-related messages.
          </P>
          <P>
            <span>OPay may discontinue the Service. </span>OPay may in its sole discretion and at any time terminate
            your access to the Service or discontinue providing the Service or any part of the Service, with or without
            notice. You agree that OPay will not be responsible or liable to you or any third party for modifying or
            discontinuing the Service, or for terminating or suspending your access to the Service.
          </P>
          <P>
            <span>OWealth specific provisions. </span>The OWealth product is offered in conjunction with and under the
            license of BlueRidge Microfinance Bank Limited. If at any time BlueRidge Microfinance Bank Limited
            terminates its agreement with OPay, the Services related to the OWealth product shall terminate immediately.
            The OWealth product shall only be made available to you once our Know Your Customer process has been
            complied with by you and your identification has been verified. Any interest shall only be payable on funds
            that have remained in your OWealth Wallet for a period of not less than one complete working day (24 (twenty
            four) working day hours) and which interest shall be at a rate determined by OPay from time to time. You
            acknowledge and agree that OPay shall have the right to amend the interest rate applicable to the OWealth
            product and you hereby indemnify and waive any/all claims of any nature against OPay for interest on your
            OWealth product. You agree and acknowledge that where the funds in your OWealth wallet have been flagged as
            or are suspected of being fraudulent in nature, OPay may immediately suspend your account and retain the
            funds pending an investigation. If you elect to lock an amount in your OWealth wallet, such lock will remain
            in place for an agreed period time. If you elect to lift the lock on the account prior to the agreed date,
            you agree that OPay may impose a penalty on you for lifting such lock and which amount OPay may immediately
            deduct from your OWealth wallet balance. Interest accrued on your OWealth wallet will be calculated annually
            and credited daily. If you wish to withdraw funds from your OWealth Wallet, such funds shall only be
            transferred to your OPay Wallet. If you are a registered KYC customer of level 1 or higher, you will have
            the option to have the funds in your OPay Wallet transfer automatically to your OWealth account on a daily
            basis. You irrevocably agree that where you select the option to transfer funds from your OPay Wallet
            balance to your OWealth account such transfer shall take place daily at a time designated by Us. The
            automatic transfer can be switched off at any time by you. At our discretion, you may be granted the option
            to effect payment for other OPay services (as designated by OPay from time to time) with funds held in your
            OWealth wallet.
          </P>
          <P>
            <span>The Service is provided without any warranties or guarantees. </span>
            THE SERVICE IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. OPAY AND ITS SUPPLIERS AND AFFILIATES DISCLAIM
            ALL WARRANTIES WITH REGARD TO THE SERVICE, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
            A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE,
            OR WITH ANY OF THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE.
          </P>
          <P>
            <span>OPay is not liable for any damages you may incur as a result of using the Services. </span>
            IN NO EVENT SHALL OPAY OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
            PERFORMANCE OF THE SERVICE, WITH THE DELAY OR INABILITY TO USE THE SERVICE, THE PROVISION OF OR FAILURE TO
            PROVIDE SERVICES, OR OTHERWISE ARISING OUT OF THE USE OF THE SERVICE, WHETHER BASED ON CONTRACT, TORT,
            NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, EVEN IF OPAY OR ANY OF ITS SUPPLIERS HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
          </P>
          <P>
            <span>You agree to arbitrate any disputes. </span>This Agreement is subject to, and shall be governed by,
            and construed in accordance with the laws of Nigeria, without reference to the principles of conflict of
            laws thereof. Any matters arising concerning the interpretation, validity or implementation of this
            Agreement not solved by mutual agreement between the Parties shall be submitted to arbitration in the
            English language before a sole arbitrator to take place in Lagos, Nigeria as the seat of the arbitration.
            The arbitration shall be conducted pursuant to the Rules of Arbitration of the Chartered Institute of
            Arbitrators of the United Kingdom. The arbitral decision shall be final and binding on the Parties and may
            be made an order of court. The Parties unconditionally consent and submit to the jurisdiction of the High
            Court of Lagos, Nigeria for such purpose. Nothing in this Agreement will be deemed as preventing OPay from
            seeking injunctive relief (or any other provisional remedy) from any court having jurisdiction over the
            Parties and the subject matter of the dispute as is necessary to protect OPay’s name, proprietary
            information, trade secrets, know-how, or any other intellectual property rights.
          </P>
          <P>
            <span>OPay may modify these Terms. </span>These Terms and related policies (including but not limited to the
            Privacy Policy) may be modified by OPay without notice at any time in the future. Changes will be posted. By
            using the Service, you agree to be bound by the latest version of these Terms. It is your responsibility to
            remain informed of any changes.
          </P>
          <P>
            <span>Alteration. </span>No alteration, variation or agreed cancellation of this agreement, and this P,
            shall be of any effect unless directed so by us.
          </P>
          <P>
            <span>Whole Agreement. </span>This Agreement constitutes the whole agreement between the parties in regard
            to the subject matter hereof and no warranties or representations of any nature whatsoever other than set
            out in this agreement have been given by any of the parties.
          </P>
          <P>
            <span>Waiver/Relaxation. </span>No relaxation or indulgence which OPay may show to you shall in any way
            prejudice or be deemed to be a waiver of its rights hereunder.
          </P>
          <P>
            <span>Survival. </span>Each and every provision of this Agreement (excluding only those provisions which are
            essential at law for a valid and binding Agreement to be constituted) shall be deemed to be separate and
            severable from the remaining provisions of this Agreement. If any of the provisions of this Agreement
            (excluding only those provisions which are essential at law for a valid and binding Agreement to be
            constituted) is found by any court of competent jurisdiction to be invalid and/or unenforceable then,
            notwithstanding such invalidity and/or unenforceability, the remaining provisions of this Agreement shall be
            and remain of full force and effect.
          </P>
        </Section>
      </Content>
    </Container>
    <Footer />
  </Layout>
);

export default TermsAndConditions;

const pageData = {
  headerData: {
    captionText: "Terms and Conditions",
    subTitleText: "Version 2.1 | 13th November 2019",
  },
};
